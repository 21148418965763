import type { ProductRepository } from 'contracts/ProductRepositoryContract'
import type {
  WidgetItem,
  WidgetProduct,
  WidgetRecommendation,
  WidgetResponse,
} from 'types/vendors/xo'

import type { FilterGroup } from '~/types/models/filter.model'
import type { Product } from '~/types/models/product'
import { USER_ID_COOKIE } from 'configuration/global.configuration'
import { isProductWidget, normalizeProduct, normalizeProducts } from 'lib/server/attraqt'

import { encodeVariables } from '~/utils/products/variables'

function normalizeItem(item: WidgetItem, tenant: any): Product | WidgetItem {
  if (isProductWidget(item))
    return normalizeProduct(item, tenant)

  return item
}

function transformFiltersToWidgetVariables(filters: FilterGroup[]): Record<string, string | string[]> | undefined {
  const filtersMap: Record<string, string> = { price_from: '$ProductPriceMin', price_to: '$ProductPriceMax' }
  const filtersWithVariables: Record<string, string> = {}

  for (const filter of filters) {
    for (const item of filter.items) {
      if (item.value !== item.metadata?.defaultValue) {
        const variable = filtersMap[item.id]

        if (variable)
          filtersWithVariables[variable] = String(item.value)
      }
    }
  }

  return filtersWithVariables
}

export const XOProductRepository: ProductRepository = (options) => {
  const cacheControl = options.cacheControl || 'undefined'
  const { tenant, profile } = options

  const repository: ReturnType<ProductRepository> = {
    _name: 'XOProductRepository',
    identifier: { profile },
    normalizers: {
      normalizeDistributions: ({ recommendations, tenant }) => {
        const itemsByDistribution: (WidgetItem | Product)[][] = []

        recommendations.forEach((recommendation) => {
          try {
            const normalizedItem = normalizeItem(recommendation.product, tenant)

            if (!itemsByDistribution[recommendation.distribution])
              itemsByDistribution[recommendation.distribution] = []

            itemsByDistribution[recommendation.distribution]!.push(normalizedItem)
          }
          catch (e) {
            console.error(e)
          }
        })

        return itemsByDistribution
      },
    },
    normalize: normalizeProducts,
    recentRequests: [],
    diagnosticReturns: [],
    async fetchProductForPDP(sku, options) {
      try {
        if (!sku)
          return Promise.reject(new Error('No sku provided'))

        const sessionId = useCookie(USER_ID_COOKIE)
        const variables = options?.variables || {}
        const encodedVariables = encodeVariables(variables)

        const { recommendations, id } = await $fetch<WidgetResponse<WidgetRecommendation[]>>(
          `/api/product/${tenant.widgets.pdp}/${sku}`,
          {
            query: {
              sessionId: sessionId.value,
              variables: encodedVariables,
            },
          },
        )

        if (!recommendations || !recommendations.length)
          return Promise.reject(new Error('No recommendations found'))

        const recommendedProductSkus = []
        const clusteredProductsSkus = []
        const products: WidgetProduct[] = []
        const similarProductSkus = []

        for (const recommendation of recommendations) {
          if (isProductWidget(recommendation.product))
            products.push(recommendation.product)

          if (recommendation.distribution === 1)
            clusteredProductsSkus.push(recommendation.id)

          if (recommendation.distribution === 2)
            recommendedProductSkus.push(recommendation.id)

          if (recommendation.distribution === 3)
            similarProductSkus.push(recommendation.id)
        }

        const normalizedProducts = this.normalize(products, tenant)

        const data = {
          id,
          products: normalizedProducts,
          cluster: clusteredProductsSkus,
          recommendations: recommendedProductSkus,
          similar: similarProductSkus,
        }

        return Promise.resolve(data)
      }
      catch (e: unknown) {
        throw new Error(`Failed to fetch product for PDP:${e}`)
      }
    },
    async fetchProducts(options) {
      const {
        widgetId = tenant.widgets.plp,
        profileId = useCookie(USER_ID_COOKIE).value,
        limit = 0,
        page = 1,
      } = options

      let variables = options.variables || {}

      if (options.filters) {
        const filters = transformFiltersToWidgetVariables(options.filters)

        if (filters) {
          variables = {
            ...variables,
            ...filters,
          }
        }
      }
      const encodedVariables = encodeVariables(variables)

      const {
        recommendations,
        id,
        pagination: { total },
      } = await $fetch<WidgetResponse<WidgetRecommendation[]>>(
        `/api/v2/products/${widgetId}/${profileId || 0}/${limit}/${page}/${encodedVariables}`,
        {
          headers: {
            'cache-control': cacheControl,
          },
        },
      )

      const pages = Math.ceil(total / limit) || 1

      return Promise.resolve({
        id,
        pages,
        items: this.normalizers.normalizeDistributions({ recommendations, tenant }),
        totalItems: total,
      })
    },
    async fetchBasketProducts(payload) {
      try {
        const { cartAmount, itemSkus, shippingThreshold } = payload
        const profileId = useCookie(USER_ID_COOKIE).value
        const variables = {
          $CartAmount: String(cartAmount),
          $selected_items_1: itemSkus,
          $ShippingThreshold: String(shippingThreshold),
          ...payload.variables,
        }
        const limit = 8

        const widgetId = payload.widgetId ?? tenant.widgets.basket

        const {
          recommendations,
          id,
          pagination: { total },
        } = await $fetch<WidgetResponse<WidgetRecommendation[]>>(
          '/api/v2/widget',
          {
            method: 'POST',
            headers: {
              'cache-control': cacheControl,
            },
            body: {
              widgetId,
              profileId,
              variables,
              limit: String(limit),
              requestName: 'Basket',
            },
          },
        )

        if (!recommendations)
          return Promise.reject(new Error('No recommendations found'))

        const pages = Math.ceil(total / limit) || 1

        return Promise.resolve({
          id,
          pages,
          items: this.normalizers.normalizeDistributions({ recommendations, tenant }),
          totalItems: total,
        })
      }
      catch (e: unknown) {
        throw new Error(`Failed to fetch basket products:${e}`)
      }
    },
  }

  return repository
}
